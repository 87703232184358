//@ts-check
import React from "react";
import { useI18n } from "components/lni18n";
import Link from "redux-first-router-link";
import useTypedSelector from "utils/useTypedSelector";
import WaitRipple from "components/WaitRipple";

const FinishedClasses = (props) => {

    const { languageService: t } = useI18n();
    const { teacherData } = useTypedSelector(state => state.classData);


    if (!teacherData) {
        return <div className="scrollContent">
            <div className="card-box big-card-box h-100 ">
                <div className="card-body h-100 pb-0">
                    <WaitRipple />
                </div>
            </div>
        </div>
    }

    const classList = teacherData.Classes.Finished;
    const num = classList.length;
    const firstHalf = classList.filter((cl, i) => (i <= num / 2));
    const secondHalf = classList.filter((cl, i) => (i > num / 2));

    return <div className="scrollContent">
        <div className="card-box big-card-box">
            <div className="card-body pb-0">

                <h3>{t.getText("stopped_courses")}</h3>

                <div className="max-col-width m-auto p-4 row">
                <div className="col-6">
                        {firstHalf.map(cl => {
                            return <div className="mb-3">
                                <Link to={{ type: "CLASS", payload: { classid: cl.Id } }}>
                                    <div className={cl.MainTeacher ? "primary-dot" : ""}>{cl.Name}</div>
                                </Link>
                            </div>
                        })}
                    </div>
                    <div className="col-6">
                        {secondHalf.map(cl => {
                            return <div className="mb-3">
                                <Link to={{ type: "CLASS", payload: { classid: cl.Id } }}>
                                    <div className={cl.MainTeacher ? "primary-dot" : ""}>{cl.Name}</div>
                                </Link>
                            </div>
                        })}
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
}

export default FinishedClasses;
